const closeVideoButton = document.querySelector('#closeVideo')
const videoModal = document.querySelector('#videoModal')
const videoIntro = document.querySelector('#video-intro')
const videoThumbnail = document.querySelector('#videoThumbnail')
const seedbed = document.querySelector('#seedbed')
const HOME_PAGE = document.querySelector('#HomePage')

class HomePage {
    constructor() {

        if (HOME_PAGE) {
            closeVideoButton.addEventListener('click', this.closeVideo)
            videoThumbnail.addEventListener('click', this.openVideo)

            videoIntro.addEventListener('ended', () => {
                seedbed.classList.add('active')
                seedbed.classList.add('animate__fadeIn')
            })
        }

    }

    closeVideo () {
        videoModal.classList.remove('is--active')
        videoIntro.pause()
        videoIntro.currentTime = 0

        // * Restart seedbeb status
        seedbed.classList.remove('active')
        seedbed.classList.remove('animate__fadeIn')
    }

    openVideo () {
        videoModal.classList.add('is--active')
        videoIntro.play()
        videoIntro.muted = false
    }
}

export default new HomePage()