import Flickity from "flickity"
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const PAGETYPE = document.querySelector('#SeedPage')

class SeedPage {
    constructor() {
        if (!PAGETYPE) return

        //* Animation for all seeds scene 00
        gsap.to('.scene__00__blob', {
            x:300,
            duration: 1.5
        })

        /* =============================================
            Scene 01 Animations
        ================================================ */
        //* Landscape desktop/responsive
        let landscapes = document.querySelectorAll('.scene__01__landscape')

        landscapes.forEach(landscape => {
            gsap.to(landscape, {
                scrollTrigger: {
                    trigger: '.scene__01',
                    toggleActions: 'restart pause reverse pause',
                },
                opacity: 1,
                duration: 2,
                delay: 1
            })
        })

        //* Hand animations
        gsap.fromTo('.scene__01__hand', {
            xPercent: -250
        }, {
            scrollTrigger: {
                trigger: '.scene__animate__01',
                scrub: true,
                start: 'top bottom',
                end: this.handEndAnimation(),
                onLeave: () => {
                    document.querySelectorAll('.scene__01__hand').forEach(hand => {
                        hand.classList.add('fix__on__scene')
                    })
                },
                onEnterBack:() => {
                    document.querySelectorAll('.scene__01__hand').forEach(hand => {
                        hand.classList.remove('fix__on__scene')
                    })
                }
            },
            xPercent: this.handDisplacement()
        })

        //* Title animation
        gsap.fromTo('.scene__01__title', {
            yPercent: 0
        }, {
            scrollTrigger: {
                trigger: '.scene__animate__01',
                start: 'top bottom%',
                end: 'bottom bottom',
                scrub: true
            },
            ease: 'none',
            yPercent: -100
        })

        /* =============================================
            Scene 02 Animations
        ================================================ */

        let lands02Items = document.querySelectorAll('.scene__02__land')

        lands02Items.forEach(land => {
            gsap.fromTo(land, {
                yPercent: this.responsiveAnimation(),
                alpha: 0
            },{
                scrollTrigger: {
                    trigger: '.scene__animate__02',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: true,
                },
                yPercent: 0,
                alpha: 1
            })
        })

        //* Animation title
        gsap.fromTo('.scene__02__title', {
            yPercent: -150
        },{
            scrollTrigger: {
                trigger: '.scene__animate__02',
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
            },
            ease: 'none',
            yPercent: 0
        })

        /* =============================================
            Scene 03 Animations
        ================================================ */
        let lands03Items = document.querySelectorAll('.scene__03__land')

        //TODO: Lands Animations
        lands03Items.forEach(land => {
            gsap.fromTo(land, {
                yPercent: 100,
                alpha: 0
            },{
                scrollTrigger: {
                    trigger: '.scene__animate__03',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: true,
                },
                yPercent: 0,
                alpha: 1
            })
        })

        //* Animation title
        gsap.fromTo('.scene__03__title', {
            yPercent: -150
        },{
            scrollTrigger: {
                trigger: '.scene__animate__03',
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
            },
            ease: 'none',
            yPercent: 0
        })

        //TODO: Animation for birds
        gsap.fromTo('.scene__03__birds', {
            yPercent: 0,
            xPercent: 0
        },{
            scrollTrigger: {
                trigger: '.scene__animate__03',
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,
            },
            ease: 'none',
            xPercent: -580,
            yPercent: -160
        })

        /* =============================================
        Scene 04 & Interactive controls animations
        ================================================ */
        let controls = document.querySelectorAll('.control')
        let interactiveGlobes = document.querySelectorAll('.interactive__globe')

        //* Function for land scene 04
        gsap.fromTo('.interactive__controls', {
            yPercent: 200
        }, {
            scrollTrigger: {
                trigger: '.scene__animate__04',
                start: 'top center',
                end: 'bottom bottom',
                scrub: true,
            },
            yPercent: 0,
            ease: 'none'
        })

        //* Function for show panels
        controls.forEach( control => {
            control.addEventListener('click', ()=> {
                console.log('click en: ', control)
                controls.forEach(control => {
                    control.parentElement.classList.remove('active')
                })
                control.parentElement.classList.add('active')
                this.showPanel(control)

                // Resizing carousel
                let carousels = document.querySelectorAll('.carousel')
                carousels.forEach(carousel => {
                    let carouselComponent = new Flickity(carousel, {
                        cellAlign: 'left'
                    })

                    carouselComponent.resize()
                })
            })
        })

        //* Animation for intro panels
        gsap.fromTo('.intro__tabs', this.introTabsAnimation() , {
            scrollTrigger: {
                trigger: '.scene__animate__04',
                start: 'top center',
                end: 'bottom bottom',
                onLeaveBack: () => {
                    let panels = document.querySelectorAll('.scene__tab')
                    let controls = document.querySelectorAll('.control')

                    panels.forEach( panel => {
                        panel.classList.remove('is--selected')
                    })
                    panels[0].classList.add('is--selected')

                    controls.forEach(control => {
                        control.parentElement.classList.remove('active')
                    })
                },
                scrub: true,
            },
            yPercent: 0,
            opacity: 1,
            ease: 'none'
        })
    }

    handEndAnimation () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // responsive
            return '60% 70%'
        } else {
            // desktop
            return 'bottom bottom'
        }
    }

    handDisplacement () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // responsive
            return 0
        } else {
            // desktop
            return 70
        }
    }

    responsiveAnimation () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // responsive
            return -200
        } else {
            // desktop
            return 100
        }
    }

    introTabsAnimation () {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // responsive
            return {
                opacity: 1
            }
        } else {
            // desktop
            return {
                yPercent: -200,
                opacity: 0
            }
        }
    }

    showPanel (control) {
        let panels = document.querySelectorAll('.scene__tab')

        panels.forEach( panel => {
            if (panel.getAttribute('data-panel') == control.getAttribute('data-panel')) {
                panel.classList.remove('animate__slideOutRight')
                panel.classList.add('animate__slideInRight')
                panel.classList.add('is--selected')
            } else {
                panel.classList.remove('animate__slideInRight')
                panel.classList.add('animate__slideOutRight')
                panel.classList.remove('is--selected')
            }
        })
    }
}

export default new SeedPage()