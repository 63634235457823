import Flickity from "flickity"
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const PAGETYPE = document.querySelector('#latam')
class LatamPage {
    constructor () {

        if (!PAGETYPE) return
        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return

        //* Map Zoom
        gsap.set(".scene__00__map", {xPercent: 0, yPercent: 0}),
        gsap.to(".scene__00__map", {
            scale: this.scaleMap(), x: "5%",
            scrollTrigger: {
                trigger: ".scene__02",
                start: "top bottom",
                end: "top 80%",
                onEnter: () => {
                    document.querySelector('#worldvision').setAttribute('src', 'assets/images/Latam/Maps/world-map-mexico.svg')
                    document.querySelector('#worldvision').classList.add('fix__on__scene')

                    // Resizing carousel
                    let carousels = document.querySelectorAll('.carousel')
                    carousels.forEach(carousel => {
                        let carouselComponent = new Flickity(carousel, {
                            cellAlign: 'left',
                            prevNextButtons: false
                        })

                        carouselComponent.resize()
                    })
                },
                onLeaveBack: () => {
                    document.querySelector('#worldvision').setAttribute('src', 'assets/images/Latam/Maps/world-map.svg')
                    //Back to original state control
                    document.querySelectorAll('.ctrl__panel').forEach(control => {
                        control.classList.remove('is--selected')
                    })
                    document.querySelectorAll('.ctrl__panel')[0].classList.add('is--selected')

                    // Back to original state panel
                    document.querySelectorAll('.latam__panels__panel').forEach(panel => {
                        panel.classList.remove('animate__slideOutRight')
                        panel.classList.remove('is--selected')
                    })
                    document.querySelectorAll('.latam__panels__panel')[0].classList.add('is--selected')
                    document.querySelectorAll('.latam__panels__panel')[0].classList.add('animate__slideInRight')
                    document.querySelector('#worldvision').classList.remove('fix__on__scene')
                },
                pin:true,
                scrub: true
            },
            opacity: 1,
            xPercent: 10
        })

        //* fadeOut items scene 01
        let scene01Items = document.querySelectorAll('.scene__01__item')

        scene01Items.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: '.scene__animate02',
                    toggleActions: 'restart reverse restart reverse',
                    start: 'top 80%',
                    end: 'bottom bottom'
                },
                opacity: 0,
                duration: 1
            })
        })

        //* Map panel controller
        let panelButtons = document.querySelectorAll('.ctrl__panel')
        panelButtons.forEach(panelButton => {

            panelButton.addEventListener('click', () => {
                panelButtons.forEach(item => {
                    item.classList.remove('is--selected')
                })
                panelButton.classList.add('is--selected')
                this.showPanel(panelButton)
                this.changeMap(panelButton)

                // Resizing carousel
                let carousels = document.querySelectorAll('.carousel')
                carousels.forEach(carousel => {
                    let carouselComponent = new Flickity(carousel, {
                        cellAlign: 'left',
                        prevNextButtons: false
                    })

                    carouselComponent.resize()
                })
            })
        })
    }

    showPanel (panelButton) {
        let latamPanels = document.querySelectorAll('.latam__panels__panel')

        latamPanels.forEach(panel => {
            if (panelButton.getAttribute('data-panel') == panel.getAttribute('data-panel')) {
                panel.classList.remove('animate__slideOutRight')
                panel.classList.add('animate__slideInRight')
                panel.classList.add('is--selected')
            } else {
                panel.classList.remove('animate__slideInRight')
                panel.classList.add('animate__slideOutRight')
                panel.classList.remove('is--selected')
            }
        })
        console.log(panelButton)
    }

    changeMap (panelButton) {
        let wvmap = document.querySelector('#worldvision')

        switch (panelButton.getAttribute('data-panel')) {
            case 'panel01':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-mexico.svg')
                break

            case 'panel02':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-bolivia.svg')
                break

            case 'panel03':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-brasil.svg')
                break

            case 'panel04':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-colombia.svg')
                break

            case 'panel05':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-ecuador.svg')
                break

            case 'panel06':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-republicadominicana.svg')
                break

            case 'panel07':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-salvador.svg')
                break

            case 'panel08':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-peru.svg')
                break

            case 'panel09':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-guatemala.svg')
                break

            case 'panel10':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-honduras.svg')
                break

            case 'panel11':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-nicaragua.svg')
                break

            case 'panel12':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-costarica.svg')
                break

            case 'panel13':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-ahiti.svg')
                break

            case 'panel14':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-venezuela.svg')
                break

            case 'panel15':
                wvmap.setAttribute('src', 'assets/images/Latam/Maps/world-map-chile.svg')
                break
        }
    }

    scaleMap () {
        if (window.innerWidth > 1400) {
            return 2.5
        } else {
            return 3.2
        }
    }
}

export default new LatamPage ()