const MENU_TITLE = document.querySelector('#MenuTitle')
const MENU_PAGE = document.querySelector('#MenuPage')
const ITEMS_TITLE = document.querySelectorAll('.menu__title')

class MenuPage {
    constructor() {
        if(!MENU_PAGE) return
        let items = document.querySelectorAll('.nav__component__items li a')

        items.forEach(item => {
            item.addEventListener('mouseover', () => {
                this.hoverItems(item)
            })
        })

        
    }

    hoverItems (item) {
        // MENU_TITLE.innerHTML = item.lastElementChild.innerHTML
        ITEMS_TITLE.forEach( title => {
            if (item.getAttribute('data-menu') === title.getAttribute('data-menu')) {
                title.classList.remove('animate__fadeOutUp')
                title.classList.add('animate__fadeInUp')
                title.classList.add('show')
            } else {
                title.classList.remove('show')
            }
        })
    }
}

export default new MenuPage()