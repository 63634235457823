import Flickity from "flickity"

const PARTNER_PAGE = document.querySelector('#PartnersPage')

class PartnersPage {

    constructor () {
        if (!PARTNER_PAGE) return
        this.getdPartners()
    }

    getdPartners () {
        fetch('assets/json/partners.json')
            .then(resp => resp.json())
            .then(partners => {
                this.buildPartnerResponsive(partners.items)
                this.buildPartnerCarousel(partners.items)
            })
    }

    buildPartnerResponsive (partners) {
        const PARTNERS_ITEMS= document.querySelector('#partnersItems')
        let partnersItem = ''

        partners.forEach(partner => {

            partnersItem += `
                <li class="partners__items__item">
                    <picture>
                        <img src="${partner.url}" alt="Aktion">
                    </picture>
                </li>
                `
        })

        PARTNERS_ITEMS.innerHTML = partnersItem
    }

    buildPartnerCarousel (partners) {
        let PARTNER_DSKTOP = document.querySelector('#partnersDsktop')
        let carouselItem = ''

        partners.forEach(partner => {

            carouselItem += `
                <li class="carousel__component__item animate__animated animate__fadeIn">
                    <picture>
                        <img src="${partner.url}" alt="${partner.name}">
                    </picture>
                </li>
            `
        })

        PARTNER_DSKTOP.innerHTML = carouselItem

        //TODO: change this for flickity events.
        setTimeout( () => {
            let flkty = new Flickity( PARTNER_DSKTOP, {
                // Options
                cellAlign: 'left',
                contain: true,
                draggable: '>1',
                groupCells: 5,
                prevNextButtons: false,
                autoPlay: 2000,
                pauseAutoPlayOnHover: false,
                fullscreen: true,
                lazyLoad: 1
            })
        }, 100)

    }
}

export default new PartnersPage()