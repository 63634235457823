const MENU_RESPONSIVE = document.querySelector('#menuButton')
const NAVBAR_NAV = document.querySelector('#navbar')
const BACK_TO = document.querySelector('#BackTo')

class HeaderComponent {
    constructor() {

        this.openMenu()
        window.onscroll = this.scrollHeader
        BACK_TO.addEventListener('click', this.backTo)
    }

    openMenu () {
        MENU_RESPONSIVE.addEventListener('click', () => {

            // * Button animation
            if(MENU_RESPONSIVE.classList.contains('active')) {
                MENU_RESPONSIVE.classList.remove('active')

                // *Close menu responsive
                //salidas
                NAVBAR_NAV.classList.remove('animate__fadeIn')
                NAVBAR_NAV.children[0].classList.remove('animate__fadeInDown')
                //entradas
                NAVBAR_NAV.classList.add('animate__fadeOut')
                NAVBAR_NAV.children[0].classList.add('animate__fadeOutUp')

                document.body.classList.remove('no__scroll')
                setTimeout(()=> {NAVBAR_NAV.classList.remove('open')}, 1000)

            } else {
                MENU_RESPONSIVE.classList.add('active')

                // *Open menu responsive
                //salidas
                NAVBAR_NAV.classList.remove('animate__fadeOut')
                NAVBAR_NAV.children[0].classList.remove('animate__fadeOutUp')
                //entradas
                NAVBAR_NAV.classList.add('animate__fadeIn')
                NAVBAR_NAV.children[0].classList.add('animate__fadeInDown')
                NAVBAR_NAV.classList.add('open')

                document.body.classList.add('no__scroll')
            }


        })
    }

    scrollHeader () {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header').classList.add('scrolling')
        } else {
            document.querySelector('header').classList.remove('scrolling')
        }
    }

    backTo () {
        window.history.back()
    }
}

export default new HeaderComponent()